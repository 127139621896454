export default {
  appIsReady: false,
  loaderStatus: true,
  globalStatus: true,
  rawConfig: null,
  config: null,
  busService: null,
  events: null,
  categories: {},
  tournaments: {},
  tempTime: 0,
  errorPageMessage: null,
  modalBoxComponent: 'Loader',
  ticketPreviewData: null,
  selectedEvent: {
    event: null,
    bet: null,
    outcome: null,
  },
  selectEventError: null,
  selectedNumbers: [],
  bonusBalls: [],
  activePrintType: 'offer',
  randomActive: false,
  ticketActions: {
    closed: [
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    // * 7Shop Internals work as follows:
    // * only if any supported action is provided
    // * for a status, the default 7Shop statuses
    // * (Preview, PrintCopy and Rebet if the game supports the rebet option)
    // * will be displayed.
    lost: [
      {
        action: 'checkStatus',
        conditions: [],
      },
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    open: [
      {
        action: 'cancel',
        conditions: [],
      },
      {
        action: 'printCopy',
        conditions: [],
      },
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    payedout: [
      {
        action: 'reprintConfirmation',
        conditions: [],
      },
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    pending: [
      {
        action: 'cancel',
        conditions: [],
      },
      {
        action: 'printCopy',
        conditions: [],
      },
      {
        action: 'checkStatus',
        conditions: [],
      },
    ],
    unknown: [
      {
        action: 'checkStatus',
        conditions: [],
      },
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    failed: [
      {
        action: 'checkStatus',
        conditions: [],
      },
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    rejected: [
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    expired: [
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    won: [
      {
        action: 'payout',
        conditions: [],
      },
      {
        action: 'rebet',
        conditions: [],
      },
    ],
    won_overdue: [
      {
        action: 'payout',
        conditions: [],
      },
      {
        action: 'rebet',
        conditions: [],
      },
    ],
  },
  appShown: false,
  betslipBlockers: [],
  fullOfferActive: false,
  searchedFullOfferValue: '',
  activeEdit: null,
  internetConnection: true,
  selectedEventCopy: {
    event: null,
    bet: null,
    outcome: null,
  },
  selectedNumbersCopy: [],
  bonusBallsCopy: [],
  futureBetsActive: false,
  futureBetsValue: '1',
};
