const groupBets = (items) => {
  const grouped = new Map();
  return items.map((item) => {
    if (item.selection === null) return item; // If we got null, we will keep it in same place
    if (!grouped.has(item.selection)) {
      // The first time we encounter it, we count all instances
      const count = items.filter((i) => i.selection === item.selection).length;
      grouped.set(item.selection, { ...item, selection: item.selection, futureBets: count });
    }
    return grouped.get(item.selection);
    // We use .filter() to remove duplicates, but leave null value in the same places.
  }).filter((item, index, self) => item.selection === null
    || self.findIndex((i) => i.selection === item.selection) === index);
};

export default groupBets;
